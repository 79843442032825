
.containerProgressSesion {
    display: flex;
    justify-content: flex-end; }

.containerBtnReporte {
    width: 100%;
    display: flex;
    justify-content: flex-end; }

.containerTitleUser {
    margin-bottom: 16px!important;
    font-family: 'CaviarBold'!important; }

.containerInfoUser {
    display: flex;

    .infoUser1 {
        margin-right: 36px; } }

.containerReport {
    width: 100%;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    margin: 24px 0px 48px 0px;

    .childSinBorderTop {
        width: 100%;
        height: auto;
        border-top: none!important; }

    .child, .childSinBorderBottom {
        width: 100%;
        height: auto;
        margin-bottom: 24px;
        padding-bottom: 16px;

        .childHeader {
            display: flex;
            padding: 8px;
            align-items: center;

            .progressHeader {
                width: 90%;
                margin-right: 16px; } }

        .dataChild {
            padding: 10px;
            display: flex;
            flex-wrap: wrap;

            .dataSesion {
                padding: 16px;
                width: 20%;
                min-width: 250px;
                margin: 4px; } } } }



