@import "../../constants.sass";

.containerActionsConfirmaciones {
    width: calc(100% - 24px);
    padding: 0px 12px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;

    div:first-child {
        margin-right: 16px; } }

@media (max-width: $bp-extra-small) {
    .containerActionsConfirmaciones {
        flex-direction: column;

        div {
            display: flex;
            flex-direction: column;
            width: 100%; } } }
