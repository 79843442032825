@import "../../../constants.sass";

.containerModalAdmin {
    display: flex;
    flex-direction: row;
    justify-content: center;

    .containerModal:first-child {
        margin-right: 16px; }

    .containerModal:last-child {
        margin-left: 16px; } }

.containerActions {
    width: calc(100% - 48px);
    padding: 0px 24px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px; }

.containerGenContra {
    width: 100%;
    display: flex;
    justify-content: flex-end; }

@media (max-width: $bp-extra-small) {
    .containerModalAdmin {
        flex-direction: column!important;
        justify-content: center;

        .containerModal {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }

        .containerModal:first-child {
            margin-right: 0px; }

        .containerModal:last-child {
            margin-left: 0px; } }

    .containerActions {
        flex-direction: column; }

    .containerGenContra {
        flex-direction: column;
        margin-bottom: 16px; } }
