@import '../../../constants.sass';

.containerModalTest {
    width: 600px; }

@media (max-width: $bp-small) {
    .containerModalTest {
        width: 480px!important; } }

@media (max-width: $bp-extra-small) {
    .containerModalTest {
        width: 100%!important; } }
