@import "../../../constants.sass";

.btnSaveSesion {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 16px; }

.divEditVideos {
    display: flex;
    justify-content: flex-end; }

.divVideos {
    display: flex;
    width: 100%;
    flex-direction: column;

    .lblVideos {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%; } }

.containerModule, .containerModuleWithAllPadding {
    display: flex;
    padding: 4px 16px;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    margin-top: -8px;
    background-color: #c7c7c7; }

.containerModuleWithAllPadding {
    padding: 16px!important; }

.btnAddModulo {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 4px 0px;
    align-items: center; }

.divIconsModulos {
    padding: 0px;
    width: auto;
    display: flex;
    justify-content: flex-end; }

.containerUsers {
    width: 100%; }

.btnBorrarSesion1 {
    display: none; }

.btnBorrarSesion2 {
    display: flex;
    height: 48px; }

.divIconsModulosDisplayNone {
    display: none; }

.AccordionModulosParent {
    flex-direction: column; }

.AccordionDetailsParent, .AccordionModulosParent {
    display: flex;
    width: 100%;

    .containerimgCur {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }

    .containerParentModule {
        display: flex;
        width: 100%; }

    .containerAdminschild:last-child {
        width: calc(50% - 8px);
        padding-left: 8px;
        margin-left: 8px;
        border-left: solid 1px #000; }

    .containerAdminschild {
        margin-top: 16px;
        width: 50%; }

    .AccordionDetailsChildSecond {
        width: 50%; }

    .AccordionDetailsChild, .AccordionModulosChild, .AccordionModulosChildTitle {
        padding: 8px; }

    .AccordionDetailsChild:last-child {
        padding: 8px 8px 8px 0px; }

    .AccordionModulosChild:first-child {
        display: flex;
        flex-direction: column;
        align-items: center; }

    .AccordionModulosChildTitle {
        width: calc(100% - 32px);
        margin: 16px 0px;
        padding: 16px;
        display: flex;
        border-radius: 4px;
        -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
        -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
        box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75); }

    .AccordionModulosChild, .AccordionModulosChildTitle {
        .titleSesion {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 8px;
            border-bottom: solid 1px rgba(0, 0, 0, 0.17); }

        .containerimgMod {
            margin-right: 16px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center; }

        .containerSesionChildDetail {
            display: flex;
            flex-wrap: wrap; }

        .titleModule {
            width: 100%;

            .titleForm {
                margin-bottom: 16px; }

            .formTitleBtn {
                display: flex;
                justify-content: flex-end; }

            .formTitle {
                display: flex;

                .formTitleModuleChild {
                    width: 50%; }

                .formTitleModuleChild:first-child {
                    margin-right: 4px; }

                .formTitleModuleChild:last-child {
                    margin-left: 4px; } } }

        .moduleSesionContainerMarginBottom {
            padding-bottom: 16px; }

        .moduleSesionContainer {
            display: flex;
            flex-direction: column;
            width: 31%;
            margin: 0px 8px;

            .AccordionDetailsSesion {
                width: calc(100% - 32px);
                margin-top: 16px;
                -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
                -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
                box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.75);
                border-radius: 4px;
                padding: 16px;

                .actionsSesion {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 16px;
                    align-items: center; } } } } }

@media (min-width: $bp-small) {
    .AccordionDetailsChild:last-child {
        width: 50%; } }

@media (max-width: $bp-small) {
    .filesDetailSesion {
        margin-top: 0px!important; }

    .btnBorrarSesion1 {
        margin-bottom: 16px;
        display: flex; }

    .AccordionModulosChild {
        width: calc(100% - 16px)!important;
        padding: 0px!important;

        .moduleSesionContainer {
            width: calc(100% - 16px)!important;
            margin: 0px!important; } }

    .AccordionDetailsParent, .AccordionModulosParent {
        flex-direction: column;

        .AccordionModulosChildTitle {
            flex-direction: column; }

        .containerAdminschild {
            width: 100%!important;

            .containerbtnAddnewAdminPermisos {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin-bottom: 16px; } }

        .containerAdminschild:last-child {
            margin: 0px!important;
            padding: 0px!important;
            border: none; } }

    .formTitle, .moduleSesionContainer {
        flex-direction: column;

        .formTitleModuleChild {
            width: 100%!important; }

        .formTitleModuleChild:first-child {
            margin-right: 0px!important; }

        .formTitleModuleChild:last-child {
            margin-left: 0px!important; } }

    .AccordionDetailsSesion {
        width: 100%!important; }

    .AccordionDetailsChild {
        width: calc(100% - 16px)!important; }

    .AccordionDetailsChild:last-child {
        padding: 0px 8px 8px 8px!important; }

    .AccordionDetailsChild:first-child {
        padding: 8px 8px 0px 8px!important; } }
