@import '../../../constants.sass';

.sectionHeader {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    article:first-child {
        width: calc(70% - 8px);
        border-right: solid 4px #263680;

        .divDescription {
            display: flex;
            flex-direction: column;
            align-items: center;

            .description {
                width: 100%;
                margin-bottom: 16px; }

            img {
                max-width: 450px;
                max-height: 500px; } } }

    article:last-child {
        width: calc(30% - 16px);
        padding-left: 16px;
        height: auto;
        display: flex;
        justify-content: flex-end; } }

@media (max-width: $bp-small) {
    .sectionHeader {
        flex-direction: column-reverse!important;

        article:first-child {
            width: 100%!important;
            border: none; }

        article:last-child {
            width: 100%!important;
            margin-bottom: 16px;
            padding-left: 0px; } } }
