@import "../../constants.sass";

.containerCambiarPass {
    width: 100%; }

.containerGenContraRecuperar {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 24px; }

@media (max-width: $bp-extra-small) {
    .containerGenContraRecuperar {
        flex-direction: column;
        margin-bottom: 0px; } }
