@import '../../../constants.sass';

.containerModalSummary {
    width: 800px;

    .containerSummary {
        display: flex;
        width: 100%;

        .summaryDonut {
            padding: 4px 16px;
            width: 30%;
            display: flex;
            flex-direction: column;
            align-items: center;
            border-right: solid 1px #000; }

        .summaryResponses {
            padding: 4px 16px;
            width: 70%;
            max-height: 400px;
            display: flex;
            flex-direction: column;
            align-items: center;
            overflow: auto; } } }

.containerActionsSummary {
    width: calc(100% - 48px);
    padding: 0px 24px;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px; }

@media (max-width: $bp-medium) {
    .containerModalSummary {
        width: 660px!important; } }

@media (max-width: $bp-small) {
    .containerActionsSummary {
        flex-direction: column; }

    .containerModalSummary {
        width: 100%!important;

        .containerSummary {
            flex-direction: column;

            .summaryDonut {
                width: 100%;
                padding: 0px;
                border: none;
                margin-bottom: 16px; }

            .summaryResponses {
                width: calc(100% - 8px);
                padding: 4px; } } } }
