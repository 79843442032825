@import "../../../constants.sass";

.containerGenContraUsuario {
    width: 100%;
    display: flex;
    justify-content: flex-end; }

@media (max-width: $bp-extra-small) {
    .containerGenContraUsuario {
        flex-direction: column; } }
