@import "../../constants.sass";

.sectionHome {
    background-color: #000;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;

    .carousel {
        width: 100%;
        height: auto;
        background: #fff; }

    .content {
        width: 75%;
        margin-top: 8px;

        .info, .catalogo, .nosotros, .ayuda {
            width: 100%;
            margin-top: 64px; }

        .ayuda {
            margin-bottom: 64px;
            margin-top: 48px!important; }

        .catalogo {
            display: flex;

            .catalogoUno, .catalogoDos {
                display: flex;
                flex-direction: column; }

            .catalogoUno {
                width: 230px;
                padding: 8px;
                margin-right: 16px; }

            .catalogoDos {
                width: calc(100% - 248px);

                .divCatalogo:first-child {
                    margin-bottom: 8px; }

                .divCatalogo {
                    display: flex;

                    .iconDiv {
                        display: flex;
                        width: 45%;
                        height: auto;
                        justify-content: center;
                        align-items: center;
                        padding: 0px 8px;

                        img {
                            width: 50%;
                            max-width: 190px; } }

                    .tituloDiv {
                        display: flex;
                        width: 55%;
                        height: 100%;
                        background: rgba(234, 234, 234, .2);
                        justify-content: center;
                        align-items: center; } } } }

        .info {
            display: flex;
            justify-content: space-between;

            .cardInfo {
                padding: 8px;
                width: 260px;
                height: 380px;
                border-radius: 4px;
                border: solid 2px rgba(234, 234, 234, .2);
                display: flex;
                flex-direction: column;
                &:hover {
                    background: rgba(234, 234, 234, .1);
                    border: solid 2px #ffeb3b;
                    .containerIcon {
                        .imgH {
                            display: block; }

                        .img {
                            display: none; } } }

                .containerIcon {
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .img {
                        display: block; }

                    .imgH {
                        display: none; }

                    .img, .imgH {
                        width: 150px; } }

                .containerButton {
                    margin-top: 24px;
                    display: flex;
                    justify-content: center; } } } } }

@media (max-width: $bp-large) {
    .sectionHome {
        .content {
            width: 90%; } } }

@media (max-width: $bp-medium) {
    .sectionHome {
        .content {
            width: 95%;

            .info {
                .cardInfo {
                    width: 210px;
                    height: 420px; } } } } }

@media (max-width: $bp-small) {
    .displayBr {
        display: none; }

    .sectionHome {
        .content {
            width: 100%;
            margin-top: 24px;

            .info, .catalogo, .nosotros, .ayuda {
                margin-top: 24px; }

            .ayuda {
                margin-top: 36px; }

            .catalogo {
                flex-direction: column;
                align-items: center;

                .catalogoUno {
                    margin-bottom: 24px;
                    margin-right: 0px;
                    width: calc(100% - 32px); }

                .catalogoDos {
                    margin-bottom: 24px;
                    width: calc(100% - 32px);

                    .divCatalogo:first-child {
                        margin-bottom: 24px; }

                    .divCatalogo:last-child {
                        flex-direction: column-reverse!important; }

                    .divCatalogo {
                        flex-direction: column;

                        .tituloDiv {
                            width: 100%;
                            height: 50px;
                            margin-bottom: 16p; }

                        .iconDiv {
                            display: flex;
                            width: 100%;
                            padding: 8px 0px!important;

                            img {
                                width: calc(50% - 16px); } } } } }

            .info {
                flex-direction: column;
                align-items: center;

                .cardInfo, .catalogoUno {
                    margin-bottom: 24px;
                    width: calc(100% - 48px); }

                .cardInfo:last-child {
                    margin-bottom: 8px; } } } } }


