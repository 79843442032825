@import "./constants.sass";

@font-face {
  font-family: 'Caviar';
  font-style: 'normal';
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fuentes/CaviarDreams.ttf') format('truetype'); }

@font-face {
  font-family: 'CaviarBold';
  font-style: 'normal';
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fuentes/CaviarDreamsBold.ttf') format('truetype'); }

@font-face {
  font-family: 'Lemon';
  font-style: 'normal';
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fuentes/LemonMilk.otf') format('opentype'); }

@font-face {
  font-family: 'LemonBold';
  font-style: 'normal';
  font-weight: normal;
  font-display: swap;
  src: url('./assets/fuentes/LemonMilkBold.otf') format('opentype'); }

div[role="presentation"] {
  top: 60px!important; }

html, body, #root {
  margin: 0;
  height: 100%;
  width: 100%; }

.home {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; }

.cardContentLogin {
  padding: 0px!important;
  background-image: url('./assets/img/login-background.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat; }

.containerModalLogin {
    width: 500px;
    padding: 20px 30px 150px 30px; }

.containerModal {
    width: 400px; }

.divCheckbox {
    display: contents;
    max-width: 396px;
    width: 100%; }

.divIconForm {
    margin-top: 0px;
    margin-bottom: 30px;
    padding: 0px;
    width: 100%;
    display: flex;
    justify-content: center; }

@media (max-width: $bp-extra-small) {
  .MuiDialog-paperWidthMd-128.MuiDialog-paperScrollBody-124 {
    max-width: 100%!important;
    width: 100%!important; }

  .MuiDialog-paper-122 {
    margin: 0px!important; }

  .MuiDialog-paperWidthSm.MuiDialog-paperScrollBody, .MuiDialog-paperWidthMd.MuiDialog-paperScrollBody {
    max-width: 100%!important;
    width: 100%!important; }

  .MuiDialog-paper {
    margin: 0px!important; }

  .MuiDialog-paperWidthSm {
    width: 100%!important; }

  .containerModal {
    width: 100%!important; }

  .containerModalLogin {
    width: 100%!important;
    padding: 0px; }

  .home {
    height: 100%;
    background-image: url('./assets/img/login-background.png');
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat; }

  .cardContentLogin {
    background: none; }

  #loginContent {
    .MuiPaper-elevation1 {
      box-shadow: none!important; } } }
