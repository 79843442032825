@import "../../constants.sass";
@import "../../index.sass";

.appBar {
    z-index: 1500!important; }

.lblDeskop {
    display: block; }

.containerUserHeader {
    background-color: white;
    padding: 8px;
    width: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    margin-right: -24px;

    .infoUserHeader {
        padding: 0px 16px;
        display: flex;
        flex-direction: column;

        .userName {
            font-family: 'Caviar'!important; }

        .userRole {
            font-family: 'CaviarBold'!important; }

        label {
            color: #000; } } }

@media (max-width: $bp-extra-small) {
    .containerUserHeader {
        margin-right: -16px; } }

@media (max-width: $bp-medium) {
    .lblDeskop {
        display: none; } }

@media (max-width: $bp-small) {
    .containerUserHeader {
        .infoUserHeader {
            display: none; } } }
